import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchDevicesUsers, fetchToggleDeviceUserApproval } from '../../../actions/user.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs'

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import InterfaceReducerUsers from '../../../intefaces/InterfaceReducerUser';
import { tr, trans } from '../../../translation/translations';
import Box from '../../shared/box/Box';
import BoxTitlebar from '../../shared/box-titlebar/BoxTitlebar';
import moment from 'moment';
import WithPreloader from '../../hoc/preloader/WithPreloader';
import TableFooter from '../../shared/table-footer/TableFooter';
import { IconNavUsers } from '../../shared/icons/IconsNavigation';


interface UsersProps {
  router: { location: string, navigate: (to: any) => any },
  user: InterfaceReducerUsers,
  fetchDevicesUsers: (options: { limit: number, skip: number }) => Promise<void>,
  fetchToggleDeviceUserApproval: (options: { device_serial: string, user_approval_id: string, user_approved: boolean  }) => Promise<void>,
}


interface UsersState {
  devices_users: Array<any>,
  devices_users_count: number,
  currentPage: number,
  perPage: number,
}


export class Users extends React.Component<UsersProps, UsersState> {


  state = {
    devices_users: [],
    devices_users_count: 0,
    perPage: 10,
    currentPage: 0,
  }


  componentDidMount(): void {
    this.getAllUsers();
  }


  getAllUsers = async () => {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.props.router.navigate(`/users?page=0&perPage=${e.target.value}`);
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage });
    this.props.router.navigate(`/users?page=${prevPage}&perPage=${this.state.perPage}`);
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage });
    this.props.router.navigate(`/users?page=${nextPage}&perPage=${this.state.perPage}`);
  }


  reloadPage = async ({ perPage, currentPage }: { perPage: number, currentPage: number }) => {
    await this.props.fetchDevicesUsers({ limit: perPage, skip: perPage * currentPage });
    if(this.props.user.status === fetchStates.success) {
      const { devices_users, devices_users_count } = this.props.user;
      this.setState({devices_users,devices_users_count });
    }
  }


  toggleDeviceUserApproval = async ({device_serial, user_approval_id, user_approved} : { device_serial: string, user_approval_id: string, user_approved: boolean }) => {
    console.log('toggleDeviceUserApproval');
    await this.props.fetchToggleDeviceUserApproval({ device_serial, user_approval_id, user_approved });
    this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage });
  }
  

  render() {

    const {devices_users,devices_users_count, currentPage, perPage } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewDeviceUsers.pageTitle) }>

        <Box>
          <BoxTitlebar title={ tr(trans.viewDeviceUsers.boxTitleManageUsersDevices) } buttons={ [] } />
          <div className="table">
            <div className="row header">
              <div className="cell"></div>
              <div className="cell">ID</div>
              <div className="cell">{ tr(trans.viewDeviceUsers.tableHeader.groupName) }</div>
              <div className="cell">{ tr(trans.viewDeviceUsers.tableHeader.email) }</div>
              <div className="cell">{ tr(trans.viewDeviceUsers.tableHeader.deviceSerial) }</div>
              <div className="cell">{ tr(trans.viewDeviceUsers.tableHeader.registered) }</div>
            </div>


              { devices_users && devices_users.map((user: any) => {
                console.log(user);
                return (
                  <div className="row" key={ user.user_device_id }>
                    <div className="cell" data-title={ tr(trans.viewDeviceUsers.tableHeader.registered) }>
                      {
                        user.user_device_auth_approved === true ? (
                          <span onClick={() => this.toggleDeviceUserApproval({ device_serial: user.device_serial, user_approval_id: user.user_id, user_approved: !user.user_device_auth_approved })} className="button button--primary button--small">{ tr(trans.viewDeviceUsers.tableButton.block) }</span>
                        ) : (
                          <span onClick={() => this.toggleDeviceUserApproval({ device_serial: user.device_serial, user_approval_id: user.user_id, user_approved: !user.user_device_auth_approved })} className="button button--primary button--small">{ tr(trans.viewDeviceUsers.tableButton.approve) }</span>
                        )
                      }
                    </div>
                    <div className="cell" data-title="ID">
                      { user.user_device_id }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDeviceUsers.tableHeader.groupName) }>
                      <span className="table-label table-label--text" style={{ background: user.group_color }}>{ user.group_title }</span>
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDeviceUsers.tableHeader.email) }>
                      <span className="table-identity-icon">
                        <IconNavUsers color="#666" size={16} />
                      </span>
                      { user.user_email }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDeviceUsers.tableHeader.deviceSerial) }>
                      { user.device_serial }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDeviceUsers.tableHeader.registered) }>
                      { moment(user.user_registered).format('YYYY-MM-DD HH:MM') }
                    </div>
                  </div>
                )
              })}

          </div>
          <TableFooter 
            currentPage={ currentPage }
            perPage={ perPage }
            itemsCount={devices_users_count }
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />
        </Box>

      </TemplatePageWithNavigation>


    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ user }) => ({ user }),
  { fetchDevicesUsers, fetchToggleDeviceUserApproval }
)(Users)))));