import { USER, PRELOADER } from '../types/reducer.types';
import { errorNotification, successNotification } from '../functions/notifications';


const fetchUser = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => async (dispatch:any) => {

  console.log('fetchUser', options);

  dispatch({ type: USER.FETCH });
  let authorizationHeader: any = [];
  dispatch({ type: PRELOADER.SHOW_PRELOADER });
  return fetch(`/api/user/${options.endpoint}`, options.options)
          .then(response => {
              authorizationHeader = response.headers.get('Authorization');
              return response.json();
            }
          )
          .then(json => {
            dispatch({ type: PRELOADER.HIDE_PRELOADER });
            if (json.errors !== undefined) {
              dispatch({ type: USER.FETCH_ERROR, ...json, authorizationHeader });
              json && json.errors && json.errors.map((item: {msg: string}) => {
                if(item.msg) {
                    errorNotification(item.msg);
                  }
                  return null;
              });
            } else {            
              dispatch({ type: options.SUCCESS_TYPE, ...json, authorizationHeader });
              json && json.success && json.success.map((item: {msg: string}) => {
                if(item.msg) {
                  successNotification(item.msg);
                }
                return null
              });
            }
          })
          .catch(error => dispatch({
            type: USER.FETCH_ERROR, errors: [{ msg: error }], authorizationHeader
          }));
  }


  export const fetchDevicesUsersCount = () => fetchUser({
    endpoint: `count`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: USER.FETCH_USER_COUNT_SUCCESS
  });


  export const fetchDevicesUsers = ({limit, skip}: { limit: number, skip:number }) => fetchUser({
    endpoint: `?limit=${limit}&skip=${skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: USER.FETCH_USER_SUCCESS
  });


  export const fetchToggleDeviceUserApproval = ({ device_serial, user_approval_id, user_approved }: { device_serial: string, user_approval_id: string, user_approved: boolean }) => fetchUser({
    endpoint: `toggle-approval/${device_serial}`,
    options: { 
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include',
      body: JSON.stringify({ user_approval_id, user_approved })
    },
    SUCCESS_TYPE: USER.FETCH_TOGGLE_DEVICE_USER_APPROVAL_SUCCESS
  });