import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchDeviceWallbox } from '../../../actions/device.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs'

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';
import { Link } from 'react-router-dom';
import { tr, trans } from '../../../translation/translations';
import Box from '../../shared/box/Box';
import BoxTitlebar from '../../shared/box-titlebar/BoxTitlebar';
import ButtonTitlebar from '../../shared/button-titlebar/ButtonTitlebar';
import moment from 'moment';
import { IconBoxDelete, IconBoxEdit, IconTablesMore } from '../../shared/icons/IconsTables';
import WithPreloader from '../../hoc/preloader/WithPreloader';
import TableFooter from '../../shared/table-footer/TableFooter';
import { IconNavDevices } from '../../shared/icons/IconsNavigation';



interface DevicesWallboxProps {
  router: { location: string, navigate: (to: any) => any },
  device: InterfaceReducerDevice,
  fetchDeviceWallbox: (options: { limit: number, skip: number }) => Promise<void>,
  wsConnected: boolean,
  wsData: any,
  dataCommandEmit: any
}


interface DevicesWallboxState {
  devices_wallbox: Array<any>,
  devices_wallbox_count: number,
  currentPage: number,
  perPage: number,
}


export class DevicesWallbox extends React.Component<DevicesWallboxProps, DevicesWallboxState> {


  state = {
    devices_wallbox: [],
    devices_wallbox_count: 0,
    perPage: 10,
    currentPage: 0,
  }


  componentDidMount(): void {
    this.getAllWallboxDevices();
  }


  getAllWallboxDevices = async () => {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage });
  }


  pushDataCommandEmit= (data:any) => {
    this.props.dataCommandEmit(data);
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.props.router.navigate(`/devices?page=0&perPage=${e.target.value}`);
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage });
    this.props.router.navigate(`/devices?page=${prevPage}&perPage=${this.state.perPage}`);
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage });
    this.props.router.navigate(`/devices?page=${nextPage}&perPage=${this.state.perPage}`);
  }


  reloadPage = async (options: { perPage: number, currentPage: number }) => {
    await this.props.fetchDeviceWallbox({ limit: options.perPage, skip: options.perPage * options.currentPage });
    if(this.props.device.status === fetchStates.success) {
      const { devices_wallbox, devices_wallbox_count } = this.props.device;
      this.setState({ devices_wallbox, devices_wallbox_count });
    }
  }
  

  render() {

    const { devices_wallbox, devices_wallbox_count, perPage, currentPage } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewDevicesWallbox.pageTitle) }>
        <Box>
          <BoxTitlebar title={ tr(trans.viewDevicesWallbox.boxTitleManageDevices) } buttons={ [ <ButtonTitlebar key="0" title={ tr(trans.viewDevicesWallbox.boxButtonAddDevice) } to="/devices/wallbox/add" primary /> ] } />

          <div className="table">
              <div className="row header">
                <div className="cell cell-buttons"></div>
                <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.title) }</div>
                <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.serialNo) }</div>
                <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.charging) }</div>
                <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.group) }</div>
                <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.registered) }</div>
              </div>


            { devices_wallbox && devices_wallbox.map((device: any) => {

                let DATA = {
                  charging: false
                };

                if(this.props.wsData && this.props.wsData.device_serial && device.device_serial === this.props.wsData.device_serial ) {
                  DATA = this.props.wsData;
                };

                return (

                  <div className="row" key={ device.device_serial }>
                    <div className="cell">
                      <Link className="table-button" to={`/devices/wallbox/edit/${ device.device_id }`}>
                        <IconBoxEdit color="#fff" size={22} />
                      </Link>
                      <Link className="table-button" to={`/devices/wallbox/delete/${ device.device_id }`}>
                        <IconBoxDelete color="#fff" size={24} />
                      </Link>
                      <Link className="table-button" to={`/devices/wallbox/${ device.device_id }`}>
                        <IconTablesMore color="#fff" size={21} />
                      </Link>
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.title) }>
                      <span className="table-identity-icon">
                        <IconNavDevices color="#666" size={16} />
                      </span>
                      { device.device_title }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.serialNo) }>
                      { device.device_serial }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.charging) }>
                      { DATA.charging === true ? 
                      (<span className="table-label table-label--circle table-label--trafic-go"></span>) :
                      (<span className="table-label table-label--circle table-label--trafic-stop"></span>)
                    }</div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.group) }>
                      <span className="table-label table-label--text" style={{ background: device.group_color }}>{ device.group_title }</span>
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.registered) }>
                      { moment(device.device_registered).format('YYYY-MM-DD') }
                    </div>
                    {
                      /*
                        <div className="cell">
                          { DATA.charging === true ? <button className="button button--primary button--small" onClick={() => this.pushDataCommandEmit({ "device_serial": device.device_serial, "message_type": "device", "message_command": "charge", "message_value": 0 })}>{ tr(trans.viewDevicesWallbox.tableButton.stopCharging) }</button> : <button className="button button--primary button--small" onClick={() => this.pushDataCommandEmit({ "device_serial": device.device_serial, "message_type": "device", "message_command": "charge", "message_value": 1 })}>{ tr(trans.viewDevicesWallbox.tableButton.startCharging) }</button> }
                        </div>
                      */
                    }
                  </div>
                );
            })}

          </div>
          <TableFooter 
            currentPage={ currentPage }
            perPage={ perPage }
            itemsCount={ devices_wallbox_count }
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ device }) => ({ device }),
  { fetchDeviceWallbox }
)(DevicesWallbox)))));