import getCookie from "../functions/cookieGet";
import { COOKIES_LANGUAGE_DEFAULT } from "../constants";


const language: any = getCookie('acenergyLanguage');
const DEFAULT_LANGUAGE: any = COOKIES_LANGUAGE_DEFAULT;

export function tr(phrase: any) {
  if(phrase) {
    // get cookie
    if(language) {
      let translation = phrase[language];
      if(translation === '') {
        translation = phrase[DEFAULT_LANGUAGE]
      } 
      return translation;
    } else {
      return phrase[DEFAULT_LANGUAGE];
    }

  } else {
    return 'Please provide a translation phrase';
  }
}


export const trans = {


  navigation: {
    home: {
      en: "Home",
      pl: "Główna",
      es: "Inicio",
      fr: "D'accueil"
    },
    dashboard: {
      en: "Dashboard",
      pl: "Panel",
      es: "Panel",
      fr: "Tableau de bord"
    },
    events: {
      en: "Events",
      pl: "Wydarzenia",
      es: "Eventos",
      fr: "Événements"
    },
    devices: {
      en: "Devices",
      pl: "Urządzenia",
      es: "Dispositivos",
      fr: "Dispositifs"
    },
    groups: {
      en: "Groups",
      pl: "Grupy",
      es: "Grupos",
      fr: "Groupes"
    },
    clients: {
      en: "Clients",
      pl: "Klienci",
      es: "Clientes",
      fr: "Clients"
    },
    users: {
      en: "Users",
      pl: "Użytkownicy",
      es: "Usuarios",
      fr: "Utilisateurs"
    },
    settings: {
      en: "Settings",
      pl: "Ustawienia",
      es: "Ajustes",
      fr: "Paramètres"
    },
    help: {
      en: "Help",
      pl: "Pomoc",
      es: "Ayuda",
      fr: "Aide"
    },
    logout: {
      en: "Logout",
      pl: "Wyloguj",
      es: "Cerrar sesión",
      fr: "Se déconnecter"
    },
  },


  form: {
    required: {
      en: "required",
      pl: "wymagane",
      es: "requerido",
      fr: "requis"
    },
  },


  pagination: {
    itemsPerPage: {
      en: "items per page",
      pl: "elementów na stronę",
      es: "artículos por página",
      fr: "éléments par page"
    }
  },


  viewNotfound: {
    pageTitle: {
      en: "Page not found",
      pl: "Strona nie znaleziona",
      es: "Página no encontrada",
      fr: "Page non trouvée"
    },
    paragraph: {
      en: "This means that we were unable to find the page you are looking for.",
      pl: "Oznacza to, że nie udało nam się znaleźć strony, której szukasz.",
      es: "Esto significa que no pudimos encontrar la página que estás buscando.",
      fr: "Cela signifie que nous n'avons pas pu trouver la page que vous recherchez."
    },
    action: {
      en: "You can do the following actions:",
      pl: "Możesz wykonać następujące czynności:",
      es: "Puedes realizar las siguientes acciones:",
      fr: "Vous pouvez effectuer les actions suivantes:"
    },
    buttonHome: {
      en: "Return to homepage",
      pl: "Powrót do strony głównej",
      es: "Regresar a la página principal",
      fr: "Retour à la page d'accueil"
    },
    buttonBack: {
      en: "Go back",
      pl: "Wróć",
      es: "Regresa",
      fr: "Retourner"
    },
  },


  viewLogin: {
    pageTitle: {
      en: "Login",
      pl: "Zaloguj sie",
      es: "Acceso",
      fr: "Se connecter"
    },
    form: {
      labelEmail: {
        en: "Email",
        pl: "Email",
        es: "Correo electrónico",
        fr: "Email"
      },
      labelPassword: {
        en: "Password",
        pl: "Hasło",
        es: "Contraseña",
        fr: "Mot de passe"
      },
      buttonLogin: {
        en: "Login",
        pl: "Zaloguj sie",
        es: "Acceso",
        fr: "Se connecter"
      },
      linkRegister: {
        en: "Register",
        pl: "Zarejestruj się",
        es: "Registro",
        fr: "Registre"
      },
      linkForgotPassword: {
        en: "Forgot your password?",
        pl: "Zapomniałeś hasła?",
        es: "¿Olvidaste tu contraseña?",
        fr: "Mot de passe oublié?"
      }
    }
  },


  viewRegister: {
    pageTitle: {
      en: "Register",
      pl: "Zarejestruj się",
      es: "Registro",
      fr: "Registre"
    },
    form: {
      labelEmail: {
        en: "Email",
        pl: "Email",
        es: "Correo electrónico",
        fr: "Email"
      },
      labelPassword: {
        en: "Password",
        pl: "Hasło",
        es: "Contraseña",
        fr: "Mot de passe"
      },
      labelConfirmPassword: {
        en: "Password confirmation",
        pl: "Potwierdzenie hasła",
        es: "Confirmación de contraseña",
        fr: "Confirmation du mot de passe"
      },
      buttonRegister: {
        en: "Register",
        pl: "Zarejestruj się",
        es: "Registro",
        fr: "Registre"
      },
      linkResendRegistrationPin: {
        en: "Resend registration PIN",
        pl: "Wyślij ponownie PIN rejestracji",
        es: "Reenviar PIN de registro",
        fr: "Renvoyer le code PIN d'enregistrement"
      }
    }
  },


  viewForgotPassword: {
    pageTitle: {
      en: "Forgot your password?",
      pl: "Zapomniałeś hasła?",
      es: "¿Olvidaste tu contraseña?",
      fr: "Mot de passe oublié?"
    },
    paragraphInstructions: {
      en: "Enter your email address and we'll send you a link to reset your password.",
      pl: "Wpisz swój adres e-mail, a my wyślemy Ci link umożliwiający zresetowanie hasła.",
      es: "Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.",
      fr: "Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe."
    },
    form: {
      labelEmail: {
        en: "Email",
        pl: "Email",
        es: "Correo electrónico",
        fr: "Email"
      },
      buttonResetPassword: {
        en: "Reset my password",
        pl: "Zresetować moje hasło",
        es: "Restablecer mi contraseña",
        fr: "Réinitialiser mon mot de passe"
      }
    }
  },


  viewRegisterPin: {
    paragraphInstructions: {
      en: "Please enter your registration PIN number:",
      pl: "Proszę podać swój rejestracyjny numer PIN:",
      es: "Por favor ingrese su número PIN de registro:",
      fr: "Veuillez saisir votre numéro PIN d'enregistrement :"
    }
  },


  viewPasswordReset: {
    pageTitle: {
      en: "Reset password",
      pl: "Zresetuj hasło",
      es: "Reset password",
      fr: "Réinitialiser le mot de passe"
    },
    paragraphInstructions: {
      en: "Please enter your reset PIN number:",
      pl: "Wprowadź swój resetowany numer PIN:",
      es: "Por favor ingrese su número PIN de restablecimiento:",
      fr: "Veuillez saisir votre code PIN de réinitialisation :"
    },
    form: {
      labelNewPassword: {
        en: "New password",
        pl: "Nowe hasło",
        es: "Nueva contraseña",
        fr: "Nouveau mot de passe"
      },
      labelNewPasswordConfirmation: {
        en: "New password confirmation",
        pl: "Potwierdzenie nowego hasła",
        es: "Nueva confirmación de contraseña",
        fr: "Confirmation du nouveau mot de passe"
      },
      buttonUpdateMyPassword: {
        en: "Update my password",
        pl: "Zaktualizuj moje hasło",
        es: "Actualizar mi contraseña",
        fr: "Mettre à jour mon mot de passe"
      },
    }
  },


  viewRegisterPinResend: {
    pageTitle: {
      en: "Didn't received your registration PIN?",
      pl: "Nie otrzymałeś PIN-u rejestracyjnego?",
      es: "¿No recibió su PIN de registro?",
      fr: "Vous n'avez pas reçu votre code PIN d'inscription ?"
    },
    paragraphInstructions: {
      en: "Enter your email address and we'll send you a link to reset your password.",
      pl: "Wpisz swój adres e-mail, a my wyślemy Ci link umożliwiający zresetowanie hasła.",
      es: "Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.",
      fr: "Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe."
    },
    form: {
      labelEmail: {
        en: "Email",
        pl: "Email",
        es: "Correo electrónico",
        fr: "Email"
      },
      buttonResendRegistrationPin: {
        en: "Resend registration PIN",
        pl: "Wyślij ponownie PIN",
        es: "Reenviar PIN",
        fr: "Renvoyer le code PIN"
      }
    }
  },


  viewDashboard: {
    pageTitle: {
      en: "Dashboard",
      pl: "Panel",
      es: "Panel",
      fr: "Tableau de bord"
    },
  },


  viewEvents: {
    pageTitle: {
      en: "Events",
      pl: "Wydarzenia",
      es: "Eventos",
      fr: "Événements"
    },
    boxTitleManageEvents: {
      en: "Manage Events",
      pl: "Zarządzaj wydarzeniami",
      es: "Administrar eventos",
      fr: "Administrer les événements"
    },
    tableHeader: {
      type: {
        en: "Type",
        pl: "Typ",
        es: "Tipo",
        fr: "Taper"
      },
      date: {
        en: "Date",
        pl: "Data",
        es: "Fecha",
        fr: "Date"
      }
    }
  },


  viewDevices: {
    pageTitle: {
      en: "Devices",
      pl: "Urządzenia",
      es: "Dispositivos",
      fr: "Dispositifs"
    },
  },


  viewDevicesWallbox: {
    pageTitle: {
      en: "Devices",
      pl: "Urządzenia",
      es: "Dispositivos",
      fr: "Dispositifs"
    },
    boxTitleManageDevices: {
      en: "Manage devices",
      pl: "Zarządzaj urządzeniami",
      es: "Administrar dispositivos",
      fr: "Gérer les appareils"
    },
    boxButtonAddDevice: {
      en: "Add Wallbox device",
      pl: "Dodaj urządzenie Wallbox",
      es: "Agregar dispositivo Wallbox",
      fr: "Ajouter un appareil Wallbox"
    },
    tableHeader: {
      title: {
        en: "Title",
        pl: "Tytuł",
        es: "Título",
        fr: "Titre"
      },
      serialNo: {
        en: "Serial No",
        pl: "Numer seryjny",
        es: "Número de serie",
        fr: "Numéro de série"
      },
      charging: {
        en: "Charging",
        pl: "Ładowanie",
        es: "Cargando",
        fr: "Chargement"
      },
      group: {
        en: "Group",
        pl: "Grupa",
        es: "Grupo",
        fr: "Groupe"
      },
      registered: {
        en: "Registered",
        pl: "Zarejestrowany",
        es: "Registrado",
        fr: "Inscrit"
      },
    },
    tableButton: {
      startCharging: {
        en: "Start charging",
        pl: "Rozpocznij ładowanie",
        es: "Empezar a cargar",
        fr: "Commencez à charger"
      },
      stopCharging: {
        en: "Stop charging",
        pl: "Przestań ładować",
        es: "Dejar de cargar",
        fr: "Arrêter de charger"
      }
    },
  },


  viewDevicesWallboxAdd: {
    pageTitle: {
      en: "Add Device",
      pl: "Dodaj urządzenie",
      es: "Agregar dispositivo",
      fr: "Ajouter un appareil"
    },
    form: {
      labelDeviceTitle: {
        en: "Device title",
        pl: "Tytuł urządzenia",
        es: "Título del dispositivo",
        fr: "Titre de l'appareil"
      },
      labelDeviceSerialNumber: {
        en: "Device serial number",
        pl: "Numer seryjny urządzenia",
        es: "Número de serie del dispositivo",
        fr: "Numéro de série de l'appareil"
      },
      labelDeviceGroupId: {
        en: "Device group",
        pl: "Grupa urządzeń",
        es: "Grupo de dispositivos",
        fr: "Groupe d'appareils"
      },
      buttonAddDevice: {
        en: "Register device",
        pl: "Zarejestruj urządzenie",
        es: "Registrar dispositivo",
        fr: "Enregistrer l'appareil"
      }
    }
  },


  viewDevicesWallboxEdit: {
    pageTitle: {
      en: "Edit Device",
      pl: "Edytuj urządzenie",
      es: "Editar dispositivo",
      fr: "Modifier l'appareil"
    },
    form: {
      labelDeviceTitle: {
        en: "Device title",
        pl: "Tytuł urządzenia",
        es: "Título del dispositivo",
        fr: "Titre de l'appareil"
      },
      labelDeviceSerialNumber: {
        en: "Device serial number",
        pl: "Numer seryjny urządzenia",
        es: "Número de serie del dispositivo",
        fr: "Numéro de série de l'appareil"
      },
      labelDeviceGroupId: {
        en: "Device group",
        pl: "Grupa urządzeń",
        es: "Grupo de dispositivos",
        fr: "Groupe d'appareils"
      },
      buttonEditDevice: {
        en: "Update device",
        pl: "Zaktualizuj urządzenie",
        es: "Actualizar dispositivo",
        fr: "Mettre à jour l'appareil"
      }
    }
  },


  viewDevicesWallboxDelete: {
    pageTitle: {
      en: "Delete device",
      pl: "Usuń urządzenie",
      es: "Eliminar dispositivo",
      fr: "Supprimer l'appareil"
    },
    form: {
      buttonDeleteDevice: {
        en: "Delete device",
        pl: "Usuń urządzenie",
        es: "Eliminar dispositivo",
        fr: "Supprimer l'appareil"
      }
    }
  },



  viewGroups: {
    pageTitle: {
      en: "Groups",
      pl: "Grupy",
      es: "Grupos",
      fr: "Groupes"
    },
    boxTitleManageGroups: {
      en: "Manage groups",
      pl: "Zarządzaj grupami",
      es: "Administrar grupos",
      fr: "Gérer les groupes"
    },
    boxButtonAddGroup: {
      en: "Add group",
      pl: "Dodaj grupę",
      es: "Agregar grupo",
      fr: "Ajouter un groupe"
    },
    tableHeader: {
      title: {
        en: "Title",
        pl: "Tytuł",
        es: "Título",
        fr: "Titre"
      },
      color: {
        en: "Color",
        pl: "Kolor",
        es: "Color",
        fr: "Couleur"
      },
      type: {
        en: "Type",
        pl: "Typ",
        es: "Tipo",
        fr: "Taper"
      },
      registered: {
        en: "Registered",
        pl: "Zarejestrowany",
        es: "Registrado",
        fr: "Inscrit"
      },
    },
    tableLabel: {
      custom: {
        en: "Custom",
        pl: "Zwyczaj",
        es: "Costumbre",
        fr: "Coutume"
      },
      primary: {
        en: "Primary",
        pl: "Podstawowy",
        es: "Primario",
        fr: "Primaire"
      },
    }
  },


  viewGroupsAdd: {
    pageTitle: {
      en: "Add group",
      pl: "Dodaj grupę",
      es: "Agregar grupo",
      fr: "Ajouter un groupe"
    },
    form: {
      labelGroupName: {
        en: "Group name",
        pl: "Nazwa grupy",
        es: "Nombre del grupo",
        fr: "Nom du groupe"
      },
      labelGroupColor: {
        en: "Group color",
        pl: "Kolor grupowy",
        es: "Color del grupo",
        fr: "Couleur du groupe"
      },
      buttonAddGroup: {
        en: "Add group",
        pl: "Dodaj grupę",
        es: "Agregar grupo",
        fr: "Ajouter un groupe"
      }
    }
  },


  viewGroupsEdit: {
    pageTitle: {
      en: "Edit group",
      pl: "Edytuj grupę",
      es: "Editar grupo",
      fr: "Modifier le groupe"
    },
    form: {
      labelGroupName: {
        en: "Group name",
        pl: "Nazwa grupy",
        es: "Nombre del grupo",
        fr: "Nom du groupe"
      },
      labelGroupColor: {
        en: "Group color",
        pl: "Kolor grupowy",
        es: "Color del grupo",
        fr: "Couleur du groupe"
      },
      buttonUpdateGroup: {
        en: "Update group",
        pl: "Aktualizuj grupę",
        es: "Grupo de actualización",
        fr: "Groupe de mise à jour"
      }
    }
  },


  viewGroupsDelete: {
    pageTitle: {
      en: "Delete group",
      pl: "Usuń grupę",
      es: "Eliminar grupo",
      fr: "Supprimer le groupe"
    },
    form: {
      buttonDeleteGroup: {
        en: "Delete group",
        pl: "Usuń grupę",
        es: "Eliminar grupo",
        fr: "Supprimer le groupe"
      }
    }
  },


  viewDeviceUsers: {
    pageTitle: {
      en: "Users",
      pl: "Użytkownicy",
      es: "Usuarios",
      fr: "Utilisateurs"
    },
    boxTitleManageUsersDevices: {
      en: "Manage users devices",
      pl: "Zarządzaj urządzeniami użytkowników",
      es: "Administrar dispositivos de usuarios",
      fr: "Gérer les appareils des utilisateurs"
    },
    boxButtonaddAppUser: {
      en: "Add user",
      pl: "Dodaj użytkownika",
      es: "Agregar usuario",
      fr: "Ajouter un utilisateur"
    },
    tableHeader: {
      deviceSerial: {
        en: "Device serial",
        pl: "Numer seryjny urządzenia",
        es: "Serie del dispositivo",
        fr: "Série de l'appareil"
      },
      type: {
        en: "Type",
        pl: "Typ",
        es: "Tipo",
        fr: "Taper"
      },
      email: {
        en: "Email",
        pl: "Email",
        es: "Correo electrónico",
        fr: "Email"
      },
      groupName: {
        en: "Group name",
        pl: "Nazwa grupy",
        es: "Nombre del grupo",
        fr: "Nom du groupe"
      },
      name: {
        en: "Name",
        pl: "Nazwa",
        es: "Nombre",
        fr: "Nom"
      },
      registered: {
        en: "Registered",
        pl: "Zarejestrowany",
        es: "Registrado",
        fr: "Inscrit"
      },
    },
    tableLabel: {
      admin: {
        en: "Administrator",
        pl: "Administrator",
        es: "Administrador",
        fr: "Administrateur"
      },
      user: {
        en: "User",
        pl: "Użytkownik",
        es: "Usuario",
        fr: "Utilisateur"
      },
    },
    tableButton: {
      approve: {
        en: "Approve",
        pl: "Zatwierdź",
        es: "Aprobar",
        fr: "Approuver"
      },
      block: {
        en: "Block",
        pl: "Zablokuj",
        es: "Bloquear",
        fr: "Bloquer"
      }
    }
  },


  viewUsers: {
    pageTitle: {
      en: "Users",
      pl: "Użytkownicy",
      es: "Usuarios",
      fr: "Utilisateurs"
    },
    boxTitleManageUsers: {
      en: "Manage users",
      pl: "Zarządzaj użytkownikami",
      es: "Administrar usuarios",
      fr: "Gérer les utilisateurs"
    },
    boxButtonaddAppUser: {
      en: "Add user",
      pl: "Dodaj użytkownika",
      es: "Agregar usuario",
      fr: "Ajouter un utilisateur"
    },
    tableHeader: {
      deviceSerial: {
        en: "Device serial",
        pl: "Numer seryjny urządzenia",
        es: "Serie del dispositivo",
        fr: "Série de l'appareil"
      },
      type: {
        en: "Type",
        pl: "Typ",
        es: "Tipo",
        fr: "Taper"
      },
      email: {
        en: "Email",
        pl: "Email",
        es: "Correo electrónico",
        fr: "Email"
      },
      name: {
        en: "Name",
        pl: "Nazwa",
        es: "Nombre",
        fr: "Nom"
      },
      registered: {
        en: "Registered",
        pl: "Zarejestrowany",
        es: "Registrado",
        fr: "Inscrit"
      },
    },
    tableLabel: {
      admin: {
        en: "Administrator",
        pl: "Administrator",
        es: "Administrador",
        fr: "Administrateur"
      },
      user: {
        en: "User",
        pl: "Użytkownik",
        es: "Usuario",
        fr: "Utilisateur"
      },
    }
  },


  viewUsersAdd: {
    pageTitle: {
      en: "Add user",
      pl: "Dodaj użytkownika",
      es: "Agregar usuario",
      fr: "Ajouter un utilisateur"
    },
    form: {
      labelUserEmail: {
        en: "Email address",
        pl: "Adres e-mail",
        es: "Dirección de correo electrónico",
        fr: "Adresse email"
      },
      labelUserTitle: {
        en: "User name",
        pl: "Nazwa użytkownika",
        es: "Nombre de usuario",
        fr: "Nom d'utilisateur"
      },
      labelUserGroups: {
        en: "User groups",
        pl: "Grupy użytkowników",
        es: "Grupos de usuarios",
        fr: "Groupes d'utilisateurs"
      },
      labelUserAuthorization: {
        en: "User permissions",
        pl: "Uprawnienia użytkownika",
        es: "Permisos de usuario",
        fr: "Autorisations utilisateur"
      },
      labelManageDevices: {
        en: "Manage devices",
        pl: "Zarządzaj urządzeniami",
        es: "Administrar dispositivos",
        fr: "Gérer les appareils"
      },
      labelManageGroups: {
        en: "Manage groups",
        pl: "Zarządzaj grupami",
        es: "Administrar grupos",
        fr: "Gérer les groupes"
      },
      labelManageUsers: {
        en: "Manage users",
        pl: "Zarządzaj użytkownikami",
        es: "Administrar usuarios",
        fr: "Gérer les utilisateurs"
      },
      labelManageEvents: {
        en: "Manage events",
        pl: "Zarządzaj wydarzeniami",
        es: "Administrar eventos",
        fr: "Gérer les événements"
      },
      labelManagePrices: {
        en: "Manage energy prices",
        pl: "Zarządzaj cenami energii",
        es: "Gestionar los precios de la energía.",
        fr: "Gérer les prix de l'énergie"
      },
      buttonaddAppUser: {
        en: "Add user",
        pl: "Dodaj użytkownika",
        es: "Agregar usuario",
        fr: "Ajouter un utilisateur"
      }
    }
  },


  viewUsersEdit: {
    pageTitle: {
      en: "Edit user",
      pl: "Edytuj użytkownika",
      es: "Editar usuario",
      fr: "Modifier l'utilisateur"
    },
    form: {
      labelUserEmail: {
        en: "Email address",
        pl: "Adres e-mail",
        es: "Dirección de correo electrónico",
        fr: "Adresse email"
      },
      labelUserTtile: {
        en: "User name",
        pl: "Nazwa użytkownika",
        es: "Nombre de usuario",
        fr: "Nom d'utilisateur"
      },
      labelUserGroups: {
        en: "User groups",
        pl: "Grupy użytkowników",
        es: "Grupos de usuarios",
        fr: "Groupes d'utilisateurs"
      },
      buttonUpdateUser: {
        en: "Update user",
        pl: "Zaktualizuj użytkownika",
        es: "Actualizar usuario",
        fr: "Mettre à jour l'utilisateur"
      }
    }
  },


  viewUsersDelete: {
    pageTitle: {
      en: "Delete user",
      pl: "Usuń użytkownika",
      es: "Eliminar usuario",
      fr: "Supprimer un utilisateur"
    },
    form: {
      buttonDeleteUser: {
        en: "Delete user",
        pl: "Usuń użytkownika",
        es: "Eliminar usuario",
        fr: "Supprimer un utilisateur"
      }
    }
  },


  viewSettings: {
    pageTitle: {
      en: "Settings",
      pl: "Ustawienia",
      es: "Ajustes",
      fr: "Paramètres"
    },
    tileNavigationTitle: {
      manageUsers: {
        en: "Manage application users",
        pl: "Zarządzaj użytkownikami aplikacji",
        es: "Administrar usuarios de la aplicación",
        fr: "Gérer les utilisateurs de l'application"
      },
      costsTariffs: {
        en: "Costs and tariffs",
        pl: "Koszty i taryfy",
        es: "Costos y tarifas",
        fr: "Coûts et tarifs"
      },
      applicationSettings: {
        en: "Application settings",
        pl: "Ustawienia aplikacji",
        es: "Configuraciones de la aplicación",
        fr: "Paramètres de l'application"
      },
      changePassword: {
        en: "Change password",
        pl: "Zmień hasło",
        es: "Cambiar la contraseña",
        fr: "Changer le mot de passe"
      },
      security: {
        en: "Security",
        pl: "Bezpieczeństwo",
        es: "Seguridad",
        fr: "Sécurité"
      },
      aboutApplication: {
        en: "About the application",
        pl: "O aplikacji",
        es: "Acerca de la aplicación",
        fr: "À propos de la candidature"
      },
    }
  },


  viewSettingsCostTariffs: {
    pageTitle: {
      en: "Costs and tariffs",
      pl: "Koszty i taryfy",
      es: "Costos y tarifas",
      fr: "Coûts et tarifs"
    },
    boxTitleEnergySettings: {
      en: "Energy price settings",
      pl: "Ustawienia cen energii",
      es: "Configuración del precio de la energía",
      fr: "Paramètres des prix de l'énergie"
    },
    form: {
      labelEnergyPriceCurrency: {
        en: "Currency",
        pl: "",
        es: "",
        fr: ""
      },
      labelEnergyPrice: {
        en: "Energy cost",
        pl: "Koszt energii",
        es: "Costo energético",
        fr: "Coût énergétique"
      },
      buttonUpdateEnergyCost: {
        en: "Update energy cost",
        pl: "Zaktualizuj koszt energii",
        es: "Actualizar costo de energía",
        fr: "Mettre à jour le coût de l'énergie"
      },
    }
  },


  viewSettingsApplicationSettings: {
    pageTitle: {
      en: "Application Settings",
      pl: "Ustawienia aplikacji",
      es: "Configuraciones de la aplicación",
      fr: "Paramètres de l'application"
    },
    boxTitleLanguageSettings: {
      en: "Language settings",
      pl: "Ustawienia języka",
      es: "Ajustes de idioma",
      fr: "Paramètres de langue"
    },
    form: {
      labelLanguage: {
        en: "Language",
        pl: "Język",
        es: "Idioma",
        fr: "Langue"
      },
    }
  },


  viewSettingsChangePassword: {
    pageTitle: {
      en: "Change password",
      pl: "Zmień hasło",
      es: "Cambiar la contraseña",
      fr: "Changer le mot de passe"
    },
    form: {
      labelOldPassword: {
        en: "Old password",
        pl: "Stare hasło",
        es: "Contraseña anterior",
        fr: "Ancien mot de passe"
      },
      labelNewPassword: {
        en: "New password",
        pl: "Nowe hasło",
        es: "Nueva contraseña",
        fr: "Nouveau mot de passe"
      },
      labelNewPasswordConfirmation: {
        en: "New password confirmation",
        pl: "Potwierdzenie nowego hasła",
        es: "Nueva confirmación de contraseña",
        fr: "Confirmation du nouveau mot de passe"
      },
      buttonChangePassword: {
        en: "Change password",
        pl: "Zmień hasło",
        es: "Cambiar la contraseña",
        fr: "Changer le mot de passe"
      },
    }
  },


  viewSettingsSecurity: {
    pageTitle: {
      en: "Security",
      pl: "Bezpieczeństwo",
      es: "Seguridad",
      fr: "Sécurité"
    },
    paragraphLogoutEverywhereInstructions: {
      en: "Using this button, you can logout from all the application on all the devices:",
      pl: "Za pomocą tego przycisku możesz wylogować się ze wszystkich aplikacji na wszystkich urządzeniach:",
      es: "Con este botón, puede cerrar sesión en todas las aplicaciones en todos los dispositivos:",
      fr: "Grâce à ce bouton, vous pouvez vous déconnecter de toutes les applications sur tous les appareils :"
    },
    buttonLogoutEverywhere: {
      en: "Logout everywhere",
      pl: "Wyloguj się wszędzie",
      es: "Cerrar sesión en todas partes",
      fr: "Déconnexion partout"
    },
    titleLoginList: {
      en: "List of latest logins",
      pl: "Lista ostatnich logowań",
      es: "Lista de los últimos inicios de sesión",
      fr: "Liste des dernières connexions"
    },
    tableHeader: {
      time: {
        en: "Time",
        pl: "Czas",
        es: "Tiempo",
        fr: "Temps"
      },
      email: {
        en: "Email",
        pl: "Email",
        es: "Correo electrónico",
        fr: "Email"
      },
      loginSuccess: {
        en: "Login success",
        pl: "Logowanie powiodło się",
        es: "Login success",
        fr: "Connexion réussie"
      },
    }
  },


  viewSettingsAboutApplication: {
    pageTitle: {
      en: "About the application",
      pl: "O aplikacji",
      es: "Acerca de la aplicación",
      fr: "À propos de la candidature"
    },
    paragraphCurrentApplicationVersion: {
      en: "Current application version is:",
      pl: "Aktualna wersja aplikacji to:",
      es: "La versión actual de la aplicación es:",
      fr: "La version actuelle de l'application est:"
    }
  },


  partialDashboardCharts: {
    partialTitle: {
      en: "Statistics",
      pl: "Statystyka",
      es: "Estadística",
      fr: "Statistiques"
    },
    chartTitleEnergy: {
      en: "Energy",
      pl: "Energia",
      es: "Energía",
      fr: "Énergie"
    },
    chartTitleCost: {
      en: "Cost",
      pl: "Koszt",
      es: "Costo",
      fr: "Coût"
    },
    chartTitleTime: {
      en: "Time",
      pl: "Czas",
      es: "Tiempo",
      fr: "Temps"
    },
    filter: {
      filterNow: {
        en: "Now",
        pl: "Teraz",
        es: "Ahora",
        fr: "Maintenant"
      },
      filterToday: {
        en: "Today",
        pl: "Dzisiaj",
        es: "Hoy",
        fr: "Aujourd'hui"
      },
      filterWeek: {
        en: "Week",
        pl: "Tydzień",
        es: "Semana",
        fr: "Semaine"
      },
      filterMonth: {
        en: "Month",
        pl: "Miesiąc",
        es: "Mes",
        fr: "Mois"
      },
      filterYear: {
        en: "Year",
        pl: "Rok",
        es: "Año",
        fr: "Année"
      },
    }
  },


  partialDashboardChargingEvents: {
    partialTitle: {
      en: "Charging Events",
      pl: "Wydarzenia związane z ładowaniem",
      es: "Eventos de carga",
      fr: "Événements de recharge"
    },
    tableHeader: {
      deviceSerialNo: {
        en: "Device Serial NO",
        pl: "Urządzenie nr seryjny",
        es: "Número de serie del dispositivo",
        fr: "Numéro de série de l'appareil"
      },
      chargingStarted: {
        en: "Charging Started",
        pl: "Ładowanie rozpoczęte",
        es: "Carga iniciada",
        fr: "Chargement démarré"
      },
      chargingEnded: {
        en: "Charging Ended",
        pl: "Ładowanie zakończone",
        es: "Carga finalizada",
        fr: "Chargement terminé"
      },
      date: {
        en: "Date",
        pl: "Data",
        es: "Fecha",
        fr: "Date"
      },
      energyConsumed: {
        en: "Energy Consumed",
        pl: "Zużyta energia",
        es: "Energía consumida",
        fr: "Énergie consommée"
      },
      chargingTime: {
        en: "Charging Time",
        pl: "Czas ładowania",
        es: "Tiempo de carga",
        fr: "Temps de charge"
      },
      totalCost: {
        en: "Total Cost",
        pl: "Całkowity koszt",
        es: "Costo total",
        fr: "Coût total"
      },
      eventType: {
        en: "Event Type",
        pl: "Typ zdarzenia",
        es: "Tipo de evento",
        fr: "Type d'événement"
      },
      error: {
        en: "Error",
        pl: "Błąd",
        es: "Error",
        fr: "Erreur"
      }
    }
  },


  partialDashboardOverview: {
    partialTitleGroups: {
      en: "Manage groups",
      pl: "Zarządzaj grupami",
      es: "Administrar grupos",
      fr: "Gérer les groupes"
    },
    partialTitleChargers: {
      en: "Manage chargers",
      pl: "Zarządzaj ładowarkami",
      es: "Administrar cargadores",
      fr: "Gérer les chargeurs"
    },
    tableHeader: {
      groupName: {
        en: "Group name",
        pl: "Nazwa grupy",
        es: "Nombre del grupo",
        fr: "Nom du groupe"
      },
      wallboxCount: {
        en: "Wallbox Count",
        pl: "Liczba Wallboxów",
        es: "Recuento de cajas de empotrar",
        fr: "Nombre de boîtes murales"
      },
      activeWallbox: {
        en: "Wallbox Active",
        pl: "Wallbox aktywny",
        es: "Wallbox activo",
        fr: "Boîte murale active"
      },
      energyConsumed: {
        en: "Energy Consumed",
        pl: "Zużyta energia",
        es: "Energía consumida",
        fr: "Énergie consommée"
      },
      eventsErrors: {
        en: "Events/Errors",
        pl: "Zdarzenia/Błędy",
        es: "Eventos/Errores",
        fr: "Événements/Erreurs"
      },
      energyCost: {
        en: "Energy Cost",
        pl: "Koszt energii",
        es: "Costo de energía",
        fr: "Coût énergétique"
      },
      registeredUsers: {
        en: "Registered Users",
        pl: "Zarejestrowani Użytkownicy",
        es: "Usuarios Registrados",
        fr: "Utilisateurs enregistrés"
      }
    }
  },


  partialDashboardInfo: {
    partialTitleGroup: {
      en: "Group details",
      pl: "Szczegóły grupy",
      es: "Detalles del grupo",
      fr: "Détails du groupe"
    },
    partialTitleCharger: {
      en: "Charger details",
      pl: "Szczegóły ładowarki",
      es: "Detalles del cargador",
      fr: "Détails du chargeur"
    },
    partialTitleLocationGroup: {
      en: "Group location",
      pl: "Lokalizacja grupy",
      es: "Ubicación del grupo",
      fr: "Localisation du groupe"
    },
    partialTitleLocationCharger: {
      en: "Charger location",
      pl: "Lokalizacja ładowarki",
      es: "Ubicación del cargador",
      fr: "Emplacement du chargeur"
    },
    itemsLabel: {
      users: {
        en: "Users",
        pl: "Użytkownicy",
        es: "Usuarios",
        fr: "Utilisateurs"
      },
      totalDevices: {
        en: "Total devices",
        pl: "Całkowita liczba urządzeń",
        es: "Dispositivos totales",
        fr: "Nombre total d'appareils"
      },
      activeDevices: {
        en: "Active devices",
        pl: "Aktywne urządzenia",
        es: "Dispositivos activos",
        fr: "Appareils actifs"
      },
      energyUsedToday: {
        en: "Energy used today",
        pl: "Energia zużyta dzisiaj",
        es: "Energía utilizada hoy",
        fr: "Énergie utilisée aujourd'hui"
      },
      cost: {
        en: "Cost",
        pl: "Koszt",
        es: "Costo",
        fr: "Coût"
      },
      deviceTitle: {
        en: "Device title",
        pl: "Tytuł urządzenia",
        es: "Título del dispositivo",
        fr: "Titre de l'appareil"
      },
      deviceSerial: {
        en: "Device serial",
        pl: "Numer seryjny urządzenia",
        es: "Serie del dispositivo",
        fr: "Numéro de série de l'appareil"
      },
      deviceRegistered: {
        en: "Device registered",
        pl: "Urządzenie zarejestrowane",
        es: "Dispositivo registrado",
        fr: "Appareil enregistré"
      },
    }
    
    
  }


}