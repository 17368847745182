import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { addGroup } from '../../../actions/group.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import './groups-add.css';
import { tr, trans } from '../../../translation/translations';
import WithWs from '../../hoc/ws/WithWs';
import InterfaceReducerGroup from '../../../intefaces/InterfaceReducerGroup';
import Box from '../../shared/box/Box';
import classnames from 'classnames';
import { IconWarning } from '../../shared/icons/IconsForm';
import WithPreloader from '../../hoc/preloader/WithPreloader';

import { CirclePicker } from 'react-color'


interface GroupAddProps {
  router: { location: string, navigate: (to: any) => any, params: { group_id: string } },
  group: InterfaceReducerGroup,
  addGroup: (group: any) => Promise<void>
}


interface GroupAddState {
  group: {
    group_title: string,
    group_color: string,
  },
  formSubmitted: boolean,
  formError: boolean
}


export class GroupAdd extends React.Component<GroupAddProps, GroupAddState> {


  state = {
    group: {
      group_title: '',
      group_color: '#3f51b5'
    },
    formSubmitted: false,
    formError: false
  }


  handleGroupColorChange = (color: any, event: any) => {
    event.preventDefault();
    this.setState({ group: { ...this.state.group, group_color: color.hex } });
  }


  handleOnGroupAddSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    await this.props.addGroup(this.state.group);
    this.setState({ formSubmitted: false });
    if(this.props.group.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
    if(this.props.group.status === fetchStates.success) {
      this.props.router.navigate('/groups');
    }
    this.setState({ formSubmitted: false });
  }
  

  render() {

    const { group, formSubmitted, formError } = this.state;
    const { fields, status } = this.props.group;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewGroupsAdd.pageTitle) } buttonBack>
        <Box>
          <form className={classnames('form', { 'form-error': formError })} onSubmit={this.handleOnGroupAddSubmit}>
            <div className="form-section">

              <div className="form-group">
                <label 
                  htmlFor="group_title"
                  className={classnames('label', { 'label--error': fields && fields.includes('group.group_title') })}
                >
                  { fields && fields.includes('group.group_title') && <IconWarning size={16} color="#fff" /> } 
                  { tr(trans.viewGroupsAdd.form.labelGroupName) }
                </label>
                <div className="input-group">
                  <input 
                      type="text"
                      name="group_title"
                      id="group_title" 
                      value={ group.group_title }
                      onChange={e => this.setState({ group: { ...group, group_title: e.target.value } })}
                      className={classnames('input input--form', { 'input--error': fields && fields.includes('group.group_title') })}
                    />
                </div>
              </div>


              <div className="form-group">
                <label
                  className={classnames('label', { 'label--error': fields && fields.includes('group.group_color') })}
                >
                    { fields && fields.includes('group.group_color') && <IconWarning size={16} color="#fff" /> } 
                    { tr(trans.viewGroupsAdd.form.labelGroupColor) }

                  <div className="input-group" >
                    <CirclePicker onChangeComplete={ this.handleGroupColorChange } colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]} color={ this.state.group.group_color }/>
                  </div>
                </label>
              </div>


              <div className="form-group">
                <input 
                  type="submit" 
                  value={ tr(trans.viewGroupsAdd.form.buttonAddGroup) }
                  className="button button--primary button--small"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>

            </div>
          </form>
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ group }) => ({ group }),
  { addGroup }
)(GroupAdd)))));