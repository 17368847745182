import { DATA, PRELOADER } from '../types/reducer.types';
import { errorNotification, successNotification } from '../functions/notifications';


const fetchData = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => async (dispatch:any) => {

  dispatch({ type: DATA.FETCH });
  let authorizationHeader: any = [];
  dispatch({ type: PRELOADER.SHOW_PRELOADER });
  
  return fetch(`/api/data/${options.endpoint}`, options.options)
          .then(response => {
              authorizationHeader = response.headers.get('Authorization');
              return response.json();
            }
          )
          .then(json => {
            dispatch({ type: PRELOADER.HIDE_PRELOADER });
            if (json.errors !== undefined) {
              dispatch({ type: DATA.FETCH_ERROR, ...json, authorizationHeader });
              json && json.errors && json.errors.map((item: {msg: string}) => {
                if(item.msg) {
                    errorNotification(item.msg);
                  }
                  return null;
              });
            } else {            
              dispatch({ type: options.SUCCESS_TYPE, ...json }, authorizationHeader);
              json && json.success && json.success.map((item: {msg: string}) => {
                if(item.msg) {
                  successNotification(item.msg);
                }
                return null
              });
            }
          })
          .catch(error => dispatch({
            type: DATA.FETCH_ERROR, errors: [{ msg: error }], authorizationHeader
          }));
  }


  export const fetchChartEnergy = (filter_time_range: string) => fetchData({
    endpoint: `chart/energy?filter_time_range=${filter_time_range}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DATA.FETCH_CHART_ENERGY_SUCCESS
  });


  export const fetchChartCost = (filter_time_range: string)  => fetchData({
    endpoint: `chart/cost?filter_time_range=${filter_time_range}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DATA.FETCH_CHART_COST_SUCCESS
  });


  export const fetchChartTime = (filter_time_range: string)  => fetchData({
    endpoint: `chart/time?filter_time_range=${filter_time_range}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DATA.FETCH_CHART_TIME_SUCCESS
  });